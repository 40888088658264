import { type FC, memo } from 'react';

import suiIcon from '@assets/images/icons/sui.svg';
import styles from './mobile-button.module.scss';

import sui from '@assets/images/mobile-button/sui.svg';
import dapp from '@assets/images/mobile-button/dapp.svg';
import turbos from '@assets/images/mobile-button/turbos.svg';
import rightArrow from '@assets/images/mobile-button/right-arrow.svg';
import { Link } from 'react-router-dom';

const MobileButton: FC = () => {
  return (
    <div className={styles.main}>
      <Link to="suiwallet://" className={styles.open} id={'link'}>
        Open with
        <img src={suiIcon} />
        Sui wallet
      </Link>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.step}>
            <img src={sui} />
            <div>Sui Wallet</div>
          </div>
          <div className={styles.img}>
            <img src={rightArrow} />
          </div>
          <div className={styles.step}>
            <img src={dapp} />
            <div>Apps</div>
          </div>
          <div className={styles.img}>
            <img src={rightArrow} />
          </div>
          <div className={styles.step}>
            <img src={turbos} />
            <div>Turbos</div>
          </div>
        </div>
        <div className={styles.bottom}>
          If you haven't installed Sui wallet yet, please install it first
        </div>
      </div>
    </div>
  );
};

export default memo(MobileButton);
