import { type FC, memo, useEffect } from 'react';
import { toast } from 'react-toastify';
import WalletConnected from './wallet-connected';
import WalletUnconnect from './wallet-unconnect';
import { useModel } from 'foca';
import { walletConnectModel } from '@models/wallet-connect.model';
import { referralLinkModel } from '@models/referral-link.model';
import { useCurrentAccount, useCurrentWallet } from '@mysten/dapp-kit';
import WalletDialog from './wallet-dialog';
import { isMobile } from 'react-device-detect';
import MobileButton from './mobile-button';

const wallets = ['sui-wallet', 'okex', 'phantom', 'tokenpocket', 'bybit'];

const ConnectWallet: FC = () => {
  const ua = navigator.userAgent || navigator.vendor;
  const wallet = useCurrentWallet();
  const account = useCurrentAccount();

  const showWallet = useModel(walletConnectModel, (state) => state.showWallet);
  const referralLinkCode = useModel(referralLinkModel, (state) => state.referralCode);

  useEffect(() => {
    if (wallet.isConnected && wallet.currentWallet) {
      toast('Connected to: ' + wallet.currentWallet.name);
    }
  }, [wallet.isConnected]);

  useEffect(() => {
    if (referralLinkCode && wallet.isConnected && account?.address) {
      referralLinkModel.invite(referralLinkCode, account.address);
    }
  }, [wallet.isConnected, account?.address]);

  if (!showWallet) return null;

  const isBrowser = ua.toLocaleLowerCase().indexOf('safari') > -1;
  const isWallet = wallets.some((wallet) => ua.toLocaleLowerCase().indexOf(wallet) > -1);

  if (isMobile && isBrowser && !isWallet) {
    return <MobileButton />;
  }

  return (
    <>
      {wallet.isConnected ? <WalletConnected /> : <WalletUnconnect />}
      <WalletDialog />
    </>
  );
};

export default memo(ConnectWallet);
